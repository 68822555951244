<template>
  <div class="pageDetail">
    <div class="ziHead">
      <img :src="indexImg" class="ziHeadImg" />
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span
            class="navMainInner"
            :class="{ active: active == item.entTChannelUuid }"
            v-for="(item, index) in navSubList"
            :key="index"
            @click="handleTab(item, index)"
          >
            {{ item.name }}
          </span>
        </div>

        <div class="navMainRight">
          <span>
            <router-link to="/"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
          </span>
          <a :href="mathedTitleUrl" class="textTitle">{{ mathedTitle }} </a>
          <span> &nbsp;> {{ pathtitle }} </span>
        </div>
      </div>
    </div>
    <div class="ziBase">
      <div class="width_100 CLine"></div>
      <div class="ziContact">
        <div class="rowContact">
          <div class="newsDetails">
            <div id="ContentView">
              <div class="ContentViewTit">
                <div
                  class="h3 text-center"
                  style="margin-top: 20; margin-bottom: 10px; font-size: 24px; line-height: 36px; padding-bottom: 8px"
                >
                  {{ newsitem.title }}
                </div>
              </div>
              <div style="background-color: #e7f4fa; height: 2px; margin: 10px auto"></div>
              <div class="content_body nrstyle" v-html="newsitem.content"></div>
              <div class="clear"></div>
              <div class="des" style="text-align: left; border-bottom: 0px">
                <span>责任编辑：{{ newsitem.editor }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>发布时间：{{ dayjs(newsitem.publishDate).format('YYYY-MM-DD') }}</span>
              </div>
              <div class="tz">
                <div class="u" v-if="newsitem.upArtUuid !== '0'">
                  <a target="_blank" :href="newsitem.upArtUuid">上一篇：{{ newsitem.upArtTitle || '无' }}</a>
                </div>
                <div class="d" v-if="newsitem.downArtUuid !== '0'">
                  <a target="_blank" :href="newsitem.downArtUuid">下一篇：{{ newsitem.downArtTitle || '无' }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChildChannel, getChannelInfo, getArtInfo } from '@/api/common'
export default {
  data() {
    return {
      mathedTitleUrl: '',
      parentId: '',
      listId: '',
      isDeail: false,
      isList: false,
      active: '',
      indexImg: '',
      pathtitle: '',
      mathedTitle: '',
      navSubList: [],
      newsitem: {},
      navList: [
        {
          id: '0018',
          name: '集团概况',
          url: '/groupProfile/00180001'
        },
        {
          id: '0019',
          name: '新闻中心',
          url: '/pressCenter/00190001'
        },
        {
          id: '0020',
          name: '业务板块',
          url: '/businessUnit/00200001'
        },
        {
          id: '0022',
          name: '党的建设',
          url: '/partyBuilding/00220001'
        },
        // {
        //   id: '0025',
        //   name: '文化频道',
        //   url: '/culture/00250009'
        // },
        {
          id: 'b11f5952-f793-4614-b9f9-aa9f1b632ca9',
          name: '信息公开',
          url: '/information/b11f5952-f793-4614-b9f9-aa9f1b632ca9'
        },

        {
          id: '001900',
          name: '甘肃建设报',
          url: '/newsPaper/001900100004'
        },
        {
          id: '0082',
          name: '联系我们',
          url: '/contactUs/0082'
        },
        {
          id: '00190007',
          name: '视频报道',
          url: '/pressCenter/radioReport/00240001'
        }
      ],
      urlName: ''
    }
  },
  computed: {},
  mounted() {
    var _this = this

    _this.active = _this.$route.params.parentId
    if (_this.$route.params.parentId == '00190007') {
      _this.parentId = _this.$route.params.parentId
      _this.urlName = 'pressCenter/radioReport'
      _this.mathedTitle = '企业视讯'
      _this.mathedTitleUrl = '/pressCenter/radioReport/00240001'
    } else if (_this.$route.params.parentId == '0086') {
      _this.parentId = _this.$route.params.parentId
      // _this.urlName = 'pressCenter/radioReport'
      _this.mathedTitle = '精品工程'
      _this.mathedTitleUrl = `/learn/${_this.$route.params.parentId}`
    } else if (_this.$route.params.parentId == '0167') {
      _this.parentId = _this.$route.params.parentId
      // _this.urlName = 'pressCenter/radioReport'
      _this.mathedTitle = '学党史 悟思想'
      _this.mathedTitleUrl = `/learn/${_this.$route.params.parentId}`
    } else if (_this.$route.params.parentId == '00240001') {
      _this.parentId = _this.$route.params.parentId
      _this.mathedTitle = '企业视讯'
      _this.mathedTitleUrl = `/pressCenter/radioReport/00240001/`
    } else {
      _this.parentId = _this.$route.params.parentId.slice(0, 4)
      _this.navList.forEach((line) => {
        if (_this.parentId == line.id) {
          _this.mathedTitle = line.name
          _this.mathedTitleUrl = line.url
          _this.urlName = line.url.split('/')[1]
        }
      })
    }
    _this.listId = _this.$route.params.id
    _this.pathtitle = _this.$route.params.name

    _this.getSubGrop()
    _this.getChannelInfo()
    _this.getArtInfo()
  },
  methods: {
    // 获取二级栏目
    getSubGrop() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: _this.parentId
      }
      getChildChannel(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navSubList = data
          data.length &&
            data.forEach((line) => {
              if (line.entTChannelUuid == _this.listId) {
                _this.pathtitle = line.name
                _this.indexImg = line.fileName
                if (line.catalog == 1) {
                  this.isDeail = true
                } else {
                  this.isList = true
                }
              }
            })
        }
      })
    },
    handleTab(item) {
      this.isDeail = false
      this.isList = false
      this.$router.push(`/${this.urlName}/${item.entTChannelUuid}`)
      this.pathtitle = item.name
      this.active = item.entTChannelUuid
      this.listId = item.entTChannelUuid
      this.indexImg = item.fileName
      this.$nextTick(() => {
        if (item.catalog == 1) {
          this.isDeail = true
        } else {
          this.isList = true
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.parentId
      }
      getChannelInfo(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pathtitle = data.name
          _this.indexImg = data.fileName
          if (data.parentUuid !== '0') {
            let data01 = {
              channelUuid: data.parentUuid
            }
            getChannelInfo(data01).then((res) => {
              const { code, data } = res
              if (code === 1 && data) {
                if (data.parentUuid == '81bf5506-c4a8-49b7-b091-6506465fe16e') {
                  _this.mathedTitle = '科技创新'
                  _this.mathedTitleUrl = '/businessUnit/technology/00200006'
                }
              }
            })
          }
        }
      })
    },
    getArtInfo() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        entTArticleUuid: _this.$route.params.id
      }
      getArtInfo(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsitem = data
        }
      })
    }
  },
  watch: {
    $route(val) {
      this.fullpath = val.fullPath
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}

.pageDetail {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
  // margin-bottom: 10px;
}
.pageDetail .ziHead {
  width: 100%;
  height: 450px;
  overflow: hidden;
  // background: url(../../assets/image/groupProfile0.jpg) center no-repeat;
  background-size: 100% 100%;
}
.pageDetail .ziHeadImg {
  width: 100% !important;
  height: 100%;
}
.pageDetail .ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.pageDetail .navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.pageDetail .navMainLeft {
  width: 900px;
  float: left;
}
.pageDetail .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
}
.pageDetail .navMainLeft a:hover {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.pageDetail .navMainLeft a.active {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.pageDetail .navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 10px;
  line-height: 24px;
}
.pageDetail .navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}

.pageDetail .navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}
.pageDetail .ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
  background-color: #f2f2f2;
}
.CLine {
  height: 24px;
  overflow: hidden;
  background: url(../../assets/image/CLine.png);
  position: absolute;
  z-index: 2;
  overflow: hidden;
}
.width_100 {
  width: 100% !important;
}
.pageDetail .ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.pageDetail .rowContact {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
.video-js {
  width: 800px;
  height: 400px;
  margin: 0 auto;
}
.ContentViewTit {
  text-align: center;
}
.newsDetails {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 1px 4px #333333;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
  box-sizing: border-box;
}

.newsDetails .auto_div {
  width: auto;
  height: auto;
  overflow: hidden;
}

.newsDetails .width_100 {
  width: 100% !important;
}
.newsDetails .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.newsDetails .news_panel .news_content_panel {
  width: auto;
  overflow: hidden;
}
.newsDetails .row {
  margin-right: -15px;
  margin-left: -15px;
}
.newsDetails #ContentView {
  overflow: hidden;
  margin: 0 auto;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
}
.newsDetails .nrstyle {
  font-family: '宋体';
  font-size: 18px;
  line-height: 30px;
}
.newsDetails .nrstyle img{
  max-width: 90% !important;
  margin: auto !important;
}

.newsDetails .clear {
  width: 100%;
  height: 10px;
  clear: both;
  overflow: hidden;
}
.newsDetails #ContentView .des {
  font-size: 14px;
  height: 40px;
  color: #caccce;
  line-height: 30px;
  margin: 10px 0px;
  padding-bottom: 10px;
}
.newsDetails #ContentView .tz {
  border-top: 1px #f7ebec solid;
  padding: 10px 0px;
  margin-top: 10px;
  text-align: left;
}
.newsDetails #ContentView .tz a {
  font-size: 14px;
  display: inline-block;
  padding: 5px 0px;
}
.newsDetails a {
  color: #333;
}
.newsDetails a,
a {
  text-decoration: none;
}
@media (max-width: 1300px) {
  .pageDetail .navMain,
  .pageDetail .rowContact,
  .newsDetails {
    width: 1000px;
  }
  .pageDetail .navMainLeft {
    width: auto;
  }
  .pageDetail .navMainRight {
    width: auto;
  }
  .pageDetail video {
    max-width: 90% !important;
  }
  .pageDetail .ziBase img {
    max-width: 90% !important;
    height: auto !important;
  }
  .pageDetail .ziHeadImg {
    width: 100% !important;
  }
}
@media (max-width: 992px) {
  .pageDetail .navMain,
  .pageDetail .rowContact,
  .newsDetails {
    width: 96%;
  }
  .newsDetails {
    padding: 20px 10px;
  }
  .newsDetails #ContentView {
    padding: 20px 10px;
  }
  .pageDetail .ziNav {
    display: none;
  }
  .pageDetail .ziBase .img {
    max-width: 88% !important;
    height: auto !important;
  }
  .pageDetail .ziHeadImg {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .pageDetail .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .pageDetail .navMainRight {
    display: none;
  }
  .pageDetail .ziHead {
    height: 200px;
  }
  .pageDetail .ziHeadImg {
    width: 100% !important;
    height: 200px;
  }
  .pageDetail .ziContact {
    padding: 0;
    margin: 0;
  }
  .newsDetails {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .ContentViewTit .h3 {
    font-size: 20px !important;
    line-height: 26px !important;
    font-weight: bold !important;
  }
}
</style>
